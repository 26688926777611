const projects = require('../projects.json')
let i = 1;

function renderProjects(number)
{
    let start = ((number - 1) * 8) + 1;
    let end = start + 8;

    let projectsContent = document.getElementById("projectsContent");
    projectsContent.innerHTML = "";
    while (start < end)
    {
        if (projects[start])
        {
            var aForNewProject = document.createElement("a");
            aForNewProject.href = projects[start].link;
            aForNewProject.target = "_blank";
            var newProject = document.createElement("div");

            var p = document.createElement("p");
            p.innerText = projects[start].title;

            newProject.classList.add("project");
            newProject.style.backgroundImage = "url( " + projects[start].image + ")";
            newProject.style.backgroundSize = "contain";
            newProject.appendChild(p);

            aForNewProject.appendChild(newProject);
            
            projectsContent.appendChild(aForNewProject);
        }
        start++;
    }
}

renderProjects(1);