module.exports = {
    "1":{
        "title": "YTFavour",
        "image": "https://i.postimg.cc/c15m3jPJ/unknown.png",
        "link": "https://ytfavour.bosquetjeremy.fr/"
    },
    "2":{
        "title": "Cub3D",
        "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/42_Logo.svg/800px-42_Logo.svg.png",
        "link": "https://github.com/jeremyBosquet/cub3d"
    },
    "3":{
        "title": "NFTAdventure",
        "image": "https://i.postimg.cc/63YWqn31/image.png",
        "link": "https://nftadventure-demo.bosquetjeremy.fr/"
    },
    "4":{
        "title": "Minishell",
        "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/42_Logo.svg/800px-42_Logo.svg.png",
        "link": "https://github.com/JeremyBosquet/minishell"
    },
    "5":{
        "title": "Pushswap",
        "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/42_Logo.svg/800px-42_Logo.svg.png",
        "link": "https://github.com/JeremyBosquet/push_swap"
    }
};